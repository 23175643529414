.paper {
  margin: 4px;
  padding: 8px 0;
  width: 100%;
  overflow: scroll;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
}

.buttons {
  width: 100%;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
}

.table {
  margin: 12px 0;
  max-height: calc(100vh - 300px);
  padding-bottom: 15px;
  background-color: white;
}

.table td {
  padding: 8px 0.8em;
  text-align: center !important;
}

.table strong {
  font-size: 18px;
  font-weight: 500;
}

.table small {
  font-size: 12px;
  font-weight: 300;
}


.table th {
  background-color: #696969;
  color: white;
  padding: 10px 1em;
  text-align: center !important;
}

.table th:first-child {
  z-index: 40 !important;
}

.nameColumn {
  background-color: #7c7c7c !important;
  color: white !important;
  text-align: left !important;
  font-size: 18px;
  z-index: 40 !important;
}

.table tfoot tr {
  background-color: #7c7c7c;
}

.table tfoot tr td,
.table tfoot tr strong,
.table th strong {
  color: white !important;
}

.table tr {
  border-bottom: 1px solid lightgrey !important;
}

.table th:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.table td:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.table .tableHeadCell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.preloader {
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 24px;
}

@media (max-height: 700px) {
  .table {
    min-height: 100vh;
  }
}

@media (max-width: 768px) {
  .paper {
    margin: 4px 0;
  }
  .table td {
    padding: 8px 4px;
    font-size: 14px !important;
  }
  .table strong {
    font-size: 16px;
    font-weight: 500;
  }
}

.expectedField .MuiInputBase-root {
  padding: 0 !important;
}

.expectedCell {
  background: #0025bb !important;
}

.factualCell {
  background: #061966 !important;
}

.longNum {
  white-space: nowrap;
}
