.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
  z-index: 10;
  min-height: 50px;
}

.headingTitle {
  display: flex;
  align-items: center;
}

.headingTitleText {
  padding-right: 5px;
  display: flex;
  align-items: center;
}

.createBot {
  cursor: pointer;
  transition: all 0.2s;
  padding: 10px 0px;
  background: none;
  border: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  position: relative;
}

.createBotSuccess {
  color: #128b12;
}

.createBotClear {
  color: #e00000;
}

.createBot:disabled {
  cursor: default;
  color: rgba(0, 0, 0, 0.2);
}

.createBot:hover:enabled {
  color: #000;
}

.createBotPopover {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  color: #fff;
  padding: 3px 5px;
  bottom: -25px;
  right: 0;
  white-space: nowrap;
  opacity: 0;
  height: 25px;
}

.createBot:hover > .createBotPopover {
  opacity: 1;
}

.botStatusSwitch:hover > .createBotPopover {
  opacity: 1;
}

.botStatusSwitch {
  position: relative;
}

.botStatusSwitchDisabled {
  position: absolute;
  background: #fff;
  opacity: .6;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.botStatusSwitchToggle {
  border: 1px solid rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  border-radius: 20px;
  height: 25px;
  width: 76px;
  padding: 0;
  color: rgba(0, 0, 0, 0.54);
  position: relative;
  box-sizing: content-box;
  margin-left: 5px;
}

.botStatusSwitchToggle_center:hover::before {
  background: transparent;
}

.botStatusSwitchToggle::before {
  content: '';
  width: 25px;
  height: 25px;
  background: rgba(25, 118, 210, 0.54);
  position: absolute;
  display: block;
  border-radius: 50%;
  left: -1px;
  top: 0;
  transform: translateX(1px);
  transition: 0.2s linear;
  z-index: 0;
}

.botStatusSwitchToggle_center::before {
  left: 25px;
  background: transparent;
  border-radius: 0;
}

.botStatusSwitchToggle_right::before {
  left: 50px;
  background: rgba(18, 139, 18, 0.54);
}

.botStatusSwitchToggleButton {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.54);
}

button.botStatusSwitchToggleButton {
  cursor: pointer;
}

button.botStatusSwitchToggleButton:hover {
  background: rgba(0, 0, 0, 0.54);
  color: #fff;
}

.paper {
  margin: 4px;
  padding: 8px 0;
  overflow: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  margin-top: 20px;
}

.mobilePaper {
  margin: 4px;
  padding: 8px 0;
  overflow: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  margin-top: 20px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph {
  margin: 4px;
  padding: 8px 0;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  position: relative;
  z-index: 9999 !important;
  padding: 20px 4px;
  width: 1000px;
  max-height: 95vh;
}

.mobileColumnHeader {
  box-sizing: border-box;
  width: 100%;
  background-color: #696969;
  color: white;
  padding: 0.3em 8px;
  text-align: center;
  font-size: 12px;
}

.mobileColumnCell {
  font-size: 12px;
  padding: 4px;
}

.mobileHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px 12px;
}

.mobileHeading > div {
    margin: 0 2px;
}

.botStatusSwitch {
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 1024px) {
  .graph {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .paper {
    margin: 4px 0;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  align-items: center;
  width: 100%;
}

.longNum {
  white-space: nowrap;
}

.controlIcons {
  padding: 2px 2px 0 2px;
  display: flex;
}
